// ==UserScript==
// @name Chartopia Export
// @description Add export options for https://chartopia.d12dev.com
// @version 1
// @match https://chartopia.d12dev.com/chart/*
// @grant GM_registerMenuCommand
// ==/UserScript==

GM_registerMenuCommand("Export to Roll20 Enhancement Suite", () => {
	let items: Record<string, {id: string, name: string}> = {};
	let count = 0;

	[...document.querySelectorAll(".chart-table-container tbody tr")].forEach(row => {
		let id = `${count++}`;
		let cols = [...row.querySelectorAll("td")];
		items[id] = {
			id,
			name: cols[1].textContent!.trim(),
		};
	});

	let tableid = document.location.pathname.match(/\d+/)![0];
	let name = document.querySelector("h2")!.textContent!.trim();
	name = name.replace(/\s*\([^(]*\)$/, "");

	let out = {
		name: name.replace(/[-\s]+/g, "-"),
		showplayers: false,
		id: `kevincox.ca-chartopia-${tableid}`,
		items,
	};

	let json = JSON.stringify(out, null, "\t");

	// Download
	let a = document.createElement("a");
	a.href = `data:application/json;base64,${btoa(json)}`;
	a.setAttribute("download", `${out.name}.json`);
	a.click();
});
